import { FC } from 'react'

import { ReactComponent as RecallMenuIcon } from '../../src/assets/Recall_menu_icon.svg'
import { ReactComponent as GPTMenuIcon } from '../../src/assets/ChatGPT_menu_icon.svg'
import { ReactComponent as WinWiseLogo } from '../../src/assets/WinWise_logo.svg'
import { ReactComponent as BambooLogo } from '../../src/assets/Bamboo_logo.svg'
import { ReactComponent as CRALogo } from '../../src/assets/customRecall_icon.svg'
import { ReactComponent as BHPLogo } from '../../src/assets/BHP_logo.svg'
import { ReactComponent as PMLogo } from '../../src/assets/PM_logo.svg'
import { appInsightsInstance } from '../api/AppInsights'
import Page from '../components/Page'
import AppCard from '../components/AppCard'
import { AppColour } from '../enums/AppColourConstants'
import { useShowFeature } from '../hooks/useShowFeature'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useChangeChat } from '../hooks/useChangeChat'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/Apps.module.sass'

const textColourBlack = '#000000'
const textColourWhite = '#ffffff'

const Home: FC = () => {
  const {
    openRecallChat,
    openChatGPT,
    openWinWise,
    openBamboo,
    openCustomRecallApp,
    openBHPStandards,
    openRioTintoStandards,
    openAusNetStandards,
    openPMAssistant,
  } = useChangeChat()
  const { t } = useLanguages()

  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Apps' })
  const showBambooChat = useShowFeature(FeatureFlagEnum.ShowBambooChat)
  const showCustomRecallChat = useShowFeature(FeatureFlagEnum.ShowCustomRecallApp)
  const showBHPStandardsApp = useShowFeature(FeatureFlagEnum.ShowBHPStandardsApp)
  const showRioTintoStandardsApp = useShowFeature(FeatureFlagEnum.ShowRioTintoStandardsApp)
  const showAusNetStandardsApp = useShowFeature(FeatureFlagEnum.ShowAusNetStandardsApp)
  const showPMApp = useShowFeature(FeatureFlagEnum.ShowPMApp)
  const showWinWiseApp = useShowFeature(FeatureFlagEnum.ShowWinWiseApp)

  return (
    <Page menu contentWrapper contentWrapperWide>
      <h1>{t('app_header')}</h1>

      <div className={Style.cards}>
        <AppCard
          appName='Recall Chat'
          AppIcon={RecallMenuIcon}
          appContents={
            <>
              <p>{t('recall_content')}</p>
              <p>{t('recall_content2')}</p>
            </>
          }
          appButtonLabel={t('recall_content3')}
          buttonColour={AppColour.RECALL_CHAT}
          onClick={openRecallChat}
          textColor={textColourBlack}
        />

        <AppCard
          appName='Secure ChatGPT'
          AppIcon={GPTMenuIcon}
          appContents={
            <>
              <p>{t('securegpt_content')}</p>
              <p>{t('securegpt_content2')}</p>
            </>
          }
          appButtonLabel={t('securegpt_content3')}
          buttonColour={AppColour.GPT_CHAT}
          onClick={openChatGPT}
          textColor={textColourBlack}
        />

        {showWinWiseApp.enabled && (
          <AppCard
            appName=''
            AppIcon={WinWiseLogo}
            appContents={
              <>
                <p>{t('winwise_content')}</p>
                <p>{t('winwise_content2')}</p>
              </>
            }
            appButtonLabel={t('winwise_content3')}
            buttonColour={AppColour.WINWISE_APP_PAGE}
            onClick={openWinWise}
            textColor={textColourBlack}
          />
        )}
        {showBambooChat.enabled && (
          <AppCard
            appName='Bamboo'
            AppIcon={BambooLogo}
            appContents={
              <>
                <p>{t('bamboo_content')}</p>
                <p>{t('bamboo_content2')}</p>
              </>
            }
            appButtonLabel={t('bamboo_content3')}
            buttonColour={AppColour.BAMBOO_APP_PAGE}
            onClick={openBamboo}
            experimental
            textColor={textColourBlack}
          />
        )}

        {showCustomRecallChat.enabled && (
          <AppCard
            appName='Knowledge Agent'
            AppIcon={CRALogo}
            appContents={
              <>
                <p>{t('custom_app_content')}</p>
                <p>{t('custom_app_content2')}</p>
              </>
            }
            appButtonLabel='Knowledge Agent'
            buttonColour={AppColour.CUSTOM_RECALL_APP}
            onClick={openCustomRecallApp}
            textColor={textColourWhite}
          />
        )}
        {showBHPStandardsApp.enabled && (
          <AppCard
            appName='Standards'
            AppIcon={BHPLogo}
            appContents={
              <>
                <p>{t('bhp_content')}</p>
                <p>{t('standards_content2')}</p>
              </>
            }
            appButtonLabel={t('bhp_content3')}
            buttonColour={AppColour.BHP_STANDARDS}
            onClick={openBHPStandards}
            textColor={textColourWhite}
          />
        )}
        {showRioTintoStandardsApp.enabled && (
          <AppCard
            appName='Rio Tinto Standards'
            appContents={
              <>
                <p>{t('rio_content')}</p>
                <p>{t('standards_content2')}</p>
              </>
            }
            appButtonLabel={t('rio_content2')}
            buttonColour={AppColour.RIO_TINTO_STANDARDS}
            onClick={openRioTintoStandards}
            textColor={textColourWhite}
          />
        )}
        {showAusNetStandardsApp.enabled && (
          <AppCard
            appName='AusNet Standards'
            appContents={
              <>
                <p>{t('ausnet_content')}</p>
                <p>{t('standards_content2')}</p>
              </>
            }
            appButtonLabel={t('ausnet_content2')}
            buttonColour={AppColour.AUS_NET_STANDARDS}
            onClick={openAusNetStandards}
            textColor={textColourWhite}
          />
        )}
        {showPMApp.enabled && (
          <AppCard
            appName='PM Assistant'
            AppIcon={PMLogo}
            appContents={
              <p>
                PM Assistant allows users to query Aurecon’s project management learnings, standards and processes. With
                our tailored knowledge base, users can access expert guidance on commercial, governance, and delivery
                frameworks to accelerate decision-making.
              </p>
            }
            appButtonLabel={'Ask PM Assistant'}
            buttonColour={AppColour.PM_APP}
            onClick={openPMAssistant}
            textColor={textColourBlack}
          />
        )}
      </div>
    </Page>
  )
}

export default Home
